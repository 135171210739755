import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ef381350"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title van-ellipsis"
};
const _hoisted_2 = {
  class: "price"
};
const _hoisted_3 = {
  class: "titles van-clearfix"
};
const _hoisted_4 = {
  class: "titlesa van-clearfix"
};
const _hoisted_5 = {
  class: "showPopup"
};
const _hoisted_6 = {
  style: {
    "margin": "16px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_list = _resolveComponent("van-list");
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_calendar = _resolveComponent("van-calendar");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_form = _resolveComponent("van-form");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_nav_bar, {
    title: "收款",
    fixed: true
  }, {
    right: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "search",
      size: "18",
      onClick: $options.showPopup
    }, null, 8, ["onClick"])]),
    _: 1
  }), _createVNode(_component_van_list, {
    class: "goods",
    loading: $data.loading,
    "onUpdate:loading": _cache[0] || (_cache[0] = $event => $data.loading = $event),
    finished: $data.finished,
    "finished-text": $data.finishedtext,
    onLoad: $options.onLoad
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.collectionlist, (item, index) => {
      return _openBlock(), _createBlock(_component_van_cell, {
        key: index,
        onClick: $event => $options.godetail(item.id)
      }, {
        title: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(item.oppAccountName), 1), _createElementVNode("div", _hoisted_2, _toDisplayString(item.amount), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(item.accountName), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(item.tradeTime), 1)]),
        _: 2
      }, 1032, ["onClick"]);
    }), 128))]),
    _: 1
  }, 8, ["loading", "finished", "finished-text", "onLoad"]), _createVNode(_component_van_tabbar, {
    modelValue: $data.active,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.active = $event),
    "active-color": "#1989fa"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      icon: "todo-list",
      onClick: $options.totabbar
    }, {
      default: _withCtx(() => [_createTextVNode("收款")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_van_tabbar_item, {
      icon: "balance-list",
      onClick: $options.totabbar
    }, {
      default: _withCtx(() => [_createTextVNode("付款")]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_van_popup, {
    show: $data.showTop,
    "onUpdate:show": _cache[7] || (_cache[7] = $event => $data.showTop = $event),
    position: "top",
    style: {
      height: '35%'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_van_form, null, {
      default: _withCtx(() => [_createVNode(_component_van_cell_group, {
        inset: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_field, {
          modelValue: $data.form.oppAccountName,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.form.oppAccountName = $event),
          name: "对方户名",
          label: "对方户名",
          placeholder: "对方户名"
        }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
          modelValue: $data.form.accountName,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.form.accountName = $event),
          name: "本方户名",
          label: "本方户名",
          placeholder: "本方户名"
        }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
          modelValue: $data.form.rangeDate,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.form.rangeDate = $event),
          "is-link": "",
          readonly: "",
          name: "calendar",
          label: "交易日期",
          placeholder: "点击选择日期",
          onClick: _cache[5] || (_cache[5] = $event => $data.showCalendar = true)
        }, null, 8, ["modelValue"]), _createVNode(_component_van_calendar, {
          show: $data.showCalendar,
          "onUpdate:show": _cache[6] || (_cache[6] = $event => $data.showCalendar = $event),
          type: "range",
          "min-date": $data.minDate,
          "max-date": $data.maxDate,
          onConfirm: $options.onConfirm
        }, null, 8, ["show", "min-date", "max-date", "onConfirm"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_6, [_createVNode(_component_van_button, {
        round: "",
        block: "",
        type: "primary",
        "native-type": "submit",
        onClick: $options.onSubmit
      }, {
        default: _withCtx(() => [_createTextVNode(" 提交 ")]),
        _: 1
      }, 8, ["onClick"])])]),
      _: 1
    })])]),
    _: 1
  }, 8, ["show"])]);
}