import { getDetail } from '@/api/global';
export default {
  data() {
    return {
      goods: {},
      show: false,
      shows: true
    };
  },
  created() {
    this.loadgetDetail();
    const codeg = this.$route.query.code;
    if (codeg) {
      window.localStorage.setItem('code', codeg);
    }
  },
  methods: {
    onClickLeft() {
      history.back();
    },
    loadgetDetail() {
      const code = window.localStorage.getItem('code');
      getDetail({
        id: this.$route.query.id,
        code: code
      }).then(res => {
        if (res.data.status === 'success') {
          this.show = true;
          this.shows = false;
          this.goods = res.data.data;
        } else {
          console.log('获取数据失败');
        }
      }).catch(err => {
        console.log('失获取数据败', err);
      });
    }
  }
};