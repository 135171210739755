import "core-js/modules/es.array.push.js";
import { getCollectionList } from '@/api/global';
export default {
  data() {
    return {
      showTop: false,
      showCalendar: false,
      goods: {},
      active: 0,
      minDate: new Date(2010, 0, 1),
      maxDate: '',
      form: {
        oppAccountName: '',
        accountName: '',
        startDate: '',
        endDate: '',
        rangeDate: ''
      },
      collectionlist: [],
      page: 0,
      loading: false,
      finished: false,
      finishedtext: '没有更多了'
    };
  },
  created() {
    this.loadgetdate();
    const codeg = this.$route.query.code;
    if (codeg) {
      window.localStorage.setItem('code', codeg);
    } else {
      this.getredirect();
    }
  },
  methods: {
    showPopup() {
      this.showTop = true;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.form.startDate = this.formatDate(start);
      this.form.endDate = this.formatDate(end);
      this.form.rangeDate = this.formatDate(start) + '/' + this.formatDate(end);
      this.showCalendar = false;
    },
    formatDate(date) {
      const dd = new Date(date);
      const y = dd.getFullYear();
      let m = dd.getMonth() + 1;
      let d = dd.getDate();
      m = m < 10 ? '0' + m : m;
      d = d < 10 ? '0' + d : d;
      return y + '-' + m + '-' + d;
    },
    loadgetdate() {
      this.maxDate = new Date(this.getDay(0));
      this.form.startDate = this.getDay(0);
      this.form.endDate = this.getDay(0);
    },
    godetail(id) {
      const code = window.localStorage.getItem('code');
      this.$router.push({
        path: '/detail',
        query: {
          id: id,
          code: code
        }
      });
    },
    totabbar() {
      const code = window.localStorage.getItem('code');
      if (this.active === 0) {
        this.$router.push({
          path: '/collection',
          query: {
            code: code
          }
        });
      } else {
        this.$router.push({
          path: '/payment',
          query: {
            code: code
          }
        });
      }
    },
    onSubmit() {
      this.showTop = false;
      const code = window.localStorage.getItem('code');
      this.page = 1;
      getCollectionList({
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        accountName: this.form.accountName,
        oppAccountName: this.form.oppAccountName,
        code: code,
        page: 1,
        per: 10
      }).then(res => {
        if (res.data.status === 'success') {
          this.collectionlist = res.data.data;
        } else {
          console.log(res.data.message);
        }
      }).catch(err => {
        console.log('失获取数据败', err);
      });
    },
    onLoad() {
      const code = window.localStorage.getItem('code');
      // 异步更新数据
      setTimeout(() => {
        const _this = this;
        _this.page++;
        getCollectionList({
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          accountName: this.form.accountName,
          oppAccountName: this.form.oppAccountName,
          code: code,
          page: _this.page,
          per: 10
        }).then(res => {
          if (res.data.status === 'success') {
            if (res.data.page_total >= _this.page) {
              res.data.data.forEach(function (item, index) {
                _this.collectionlist.push(item);
              });
              _this.loading = false;
            } else {
              _this.finished = true;
            }
          } else {
            _this.finishedtext = res.data.message;
            _this.loading = false;
            _this.finished = true;
          }
        }).catch(err => {
          console.log('保存失败', err);
        });
      }, 1000);
    },
    getredirect() {
      const REDIRECTURIs = 'https://fk.gangerp.com/collection';
      const REDIRECTURIa = encodeURIComponent(REDIRECTURIs);
      const url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww60a7e569d504e44f&redirect_uri=' + REDIRECTURIa + '&response_type=code&scope=snsapi_base&state=STATE&agentid=AGENTID#wechat_redirect';
      location.href = url;
    },
    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getDay(day) {
      const today = new Date();
      const targetdaymilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetdaymilliseconds);
      const tYear = today.getFullYear();
      let tMonth = today.getMonth();
      let tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + '-' + tMonth + '-' + tDate;
    },
    doHandleMonth(month) {
      let m = month;
      if (month.toString().length === 1) {
        m = '0' + month;
      }
      return m;
    }
  }
};